<template>
  <div class="bg-white rounded-lg h-full w-full py-3 px-6 md:relative">
        <Heading title="Resume"></Heading>
        <Menu class="fixed overflow-hidden mob-menu"></Menu>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 pt-5">
            <div class="p-3 pt-0 md:col-span-1 lg:col-span-2 lg:mx-2 ">
               <div class="flex">
                <img  src="../assets/img/edu1.png"><p class="text-3xl pl-3 font-bold text-gray-700"> Education</p>
                </div>
                <div class="timeline">
                        <article class="timeline__item relative">
                            <h5 class="title relative text-lg font-bold" style="    top: -.5rem; margin-bottom:0px">BE in Computer Science &amp; Engineering</h5>
                            <span class="timeline__period">2018 — 2021 ( In-Progress )</span>
                            <p class="timeline__description">SLTIET - Rajkot</p>
                        </article>
                        <article class="timeline__item relative">
                            <h5 class="title relative text-lg font-bold" style="    top: -.5rem; margin-bottom:0px">DE in Computer Engineering</h5>
                            <span class="timeline__period">2015 — 2018</span>
                            <p class="timeline__description">Government Polytechnic Bhuj</p>
                        </article>
                </div>

            </div>
            <div class="p-3 pt-0 md:col-span-1 lg:col-span-2 lg:mx-2">
                <div class="flex">
                <img  src="../assets/img/edu2.png"><p class="text-3xl pl-3 font-bold text-gray-700"> Experience</p>
                </div>
                <div class="timeline">
                        <article class="timeline__item relative">
                            <h5 class="title relative text-lg font-bold" style="    top: -.5rem; margin-bottom:0px">Freelancer Web Developer</h5>
                            <span class="timeline__period">Jan 2020 — Present</span>
                            <p class="timeline__description"></p>
                        </article>
                        <article class="timeline__item relative">
                            <h5 class="title relative text-lg font-bold" style="    top: -.5rem; margin-bottom:0px">Full Stack Developer | Managing Director</h5>
                            <span class="timeline__period">May 2019 — Jan 2020</span>
                            <p class="timeline__description">EasySoft</p>
                        </article>
                        <article class="timeline__item relative">
                            <h5 class="title relative text-lg font-bold" style="    top: -.5rem; margin-bottom:0px">Web Developer</h5>
                            <span class="timeline__period">Jan 2019 — May 2019</span>
                            <p class="timeline__description">Solomo360</p>
                        </article>
                        <article class="timeline__item relative">
                            <h5 class="title relative text-lg font-bold" style="    top: -.5rem; margin-bottom:0px">Project Manager</h5>
                            <span class="timeline__period">Sep 2016 — April 2017</span>
                            <p class="timeline__description">AdShoppy</p>
                        </article>
                </div>
            </div>

        </div>
  </div>
</template>
<script>
import Heading from '../components/Heading.vue'
import Menu from '../components/Menu.vue'

export default {
    components:{
        Heading,
        Menu
    },
    created () {
        document.title = "Resume - Freelancer Web Developer | Paras Suthar Darji | Web Developer in Rajkot | Web Developer in Gandhidham";
    }
   
}
</script>
<style scoped>
.timeline__item:before {
    content: "";
    background-color: #63B3ED;
    box-shadow: 0 0 0 3px #63B3ED;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -5px;
    height: 10px;
    width: 10px;
}


.timeline {
    margin-top: 1.8rem;
    padding-left: .5rem;
}
.timeline__item:last-child {
    border: 0;
}
.timeline__item {
    border-left: 2px solid #cbcbce;
    padding-bottom: .6rem;
    padding-left: 1.5rem;
    position: relative;
}
@media only screen and (max-width: 580px)
{
.mob-menu{
    top:0;
    left:0;
    height: auto;
}
}
</style>
