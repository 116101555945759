import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Learning from '../views/Learning.vue'
import Resume from '../views/Resume.vue'
import PageNotFound from '../views/PageNotFound.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'About',
    component: About,
    meta: {
        title: 'Paras Suthar',
        metaTags: [
          {
            name: 'description',
            content: "I'm Paras Suthar, a full stack developer specialist at Laravel, Vue.js, WordPress &  In Core PHP."
          },
          {
            property: 'og:description',
            content: 'About Me - Paras Suthar'
          }
        ]
      }
  },
  {
    path: '/Learning',
    name: 'Learning',
    component: Learning,
    meta: {
        title: 'Learing - Paras Suthar | Web Developer in Rajkot | Web Developer in Gandhidham',
        metaTags: [
          {
            name: 'description',
            content: "There a lot of things to learn and I have learned a few of these."
          },
          {
            property: 'og:description',
            content: 'Learing - Paras Suthar'
          }
        ]
      }
  },
  {
    path: '/Resume',
    name: 'Resume',
    component: Resume,
    meta: {
        title: 'Resume - Paras Suthar | Web Developer in Rajkot | Web Developer in Gandhidham',
        metaTags: [
          {
            name: 'description',
            content: "All About my studies and Work Experience."
          },
          {
            property: 'og:description',
            content: 'Resume - Paras Suthar'
          }
        ]
      }
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact,
    meta: {
        title: 'Contact - Paras Suthar | Web Developer in Rajkot | Web Developer in Gandhidham',
        metaTags: [
          {
            name: 'description',
            content: "Thanks for reaching out me. If you have any suggestions, comments or just want to chat, please feel free to send message."
          },
          {
            property: 'og:description',
            content: 'Contact Me - Paras Suthar'
          }
        ]
      }
  },
  {
    path: "*", 
    component: PageNotFound 
  },
  
];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
