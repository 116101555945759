<template>
  <div id="app" style="background-color:#F5F6F7" class="h-full w-full mt-24 grid p-3 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-6 xl:grid-cols-8 md:p-5 lg:p-6 xl:p-8">
    <Profile class="lg:col-span-2 xl:col-span-2"/>
    <div id="nav" class="lg:col-span-4 xl:col-span-6 lg:pl-6 xl:pl-8">
        <link rel="stylesheet" 
        href="https://use.fontawesome.com/releases/v5.2.0/css/all.css" 
        integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ" 
        crossorigin="anonymous">
        <!-- <router-link  to="/"></router-link> 
        <router-link  to="/learning"></router-link>
        <router-link  to="/resume"></router-link> -->
        <!-- <router-link  to="/blog">Blog</router-link> -->
        <!-- <router-link  to="/Contact"></router-link> -->
    <router-view class="shadow-md lg:m-0 mt-5"/>
    <div class="text-center p-5 text-gray-700">&copy; 2018-{{current_year}}</div>
    </div>
  </div>
</template>
<script>
import Profile from './components/Profile.vue'
export default {
    name:'App',
    components:{
        Profile,
    },
    computed: {
        current_year() {
            return new Date().getFullYear(); 
        }
    },
}
</script>
<style scoped>

</style>
