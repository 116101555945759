<template>
 <div class="bg-white rounded-lg h-full w-full pt-3 md:relative">
    <Heading class="px-6" title="Hello!" msg="Thanks for reaching out me. If you have any suggestions, comments or just want to chat, please feel free to send message."></Heading>
    <Menu class="fixed overflow-hidden mob-menu"></Menu>
    <div v-if="!sent" class=" rounded-lg rounded-t-none mt-3">
         <form @submit="formSubmit">
         <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 pt-5">
           
            <div class="p-3 pt-0 md:col-span-1 lg:col-span-2 lg:mx-2">
                <div class="relative">
                    <input type="text" v-model="form.name" class=" rounded-full font-semibold w-full h-12 pl-12 bg-gray-200 outline-none focus:bg-blue-300 focus:text-white" required placeholder="Your Name"/>
                    <i class="fas fa-user absolute fa-lg text-gray-500 input_img"  ></i>

                </div>
            </div>
            <div class="p-3 pt-0 md:col-span-1 lg:col-span-2 lg:mx-2">
                <div class="relative">
                    <input type="text" v-model="form.email" class=" rounded-full font-semibold w-full h-12 pl-12 bg-gray-200 outline-none focus:bg-blue-300 focus:text-white" required placeholder="Email Address"/>
                    <i class="fas fa-envelope absolute fa-lg text-gray-500 input_img" ></i>

                </div>
            </div>
         </div>
         <div class=" grid-cols-1 p-3 lg:mx-2">
              <div class="relative">
                    <textarea v-model="form.msg"  class="p-8 rounded-lg w-full font-semibold h-48 px-12 bg-gray-200 outline-none focus:bg-blue-300 focus:text-white" required placeholder="Your Message"></textarea>
                    
                </div>
         </div>
         <div class=" grid-cols-1 p-3 lg:mx-2 ">
            <div class="relative">
                <button class="bg-blue-400 hover:bg-blue-600 text-white outline-none font-bold py-4 px-10 pl-16 rounded-full" type="submit">Send Message</button>
                <i class="fas fa-paper-plane absolute fa-lg text-white" id="input_img_btn" ></i>

            </div>
         </div>
        </form>
                <!-- <button class="bg-blue-400 hover:bg-blue-600 text-white outline-none font-bold py-4 px-10 pl-16 rounded-full" @click="getReq" >get Message</button> -->

    </div>
    <div v-else class=" bg-gray-100 text-center mx-auto mt-24 text-4xl text-green-700 font-bold">Thank You 3000!<br/> Your Message is send Successfully
    </div>
</div>
</template>
<script>
import Heading from '../components/Heading.vue'
import Menu from '../components/Menu.vue'


export default {
    components:{
        Heading,
        Menu
    },
   
     data() {
        return {
          sent: false,
          form: {
              name: "",
              email:"",
              msg:""
              
          }
        };
    },
    
    methods: 
        {
            formSubmit(e) {
                e.preventDefault();
                console.log(this.form);
               this.axios.post('http://vue-demo.magixclicks.com/contactFrm.php', {
                    name: this.form.name,
                    mail: this.form.email,
                    msg: this.form.msg,
                })
                .then(function (response) {
                     console.log(response.data);
                     this.sent=true;
                }.bind(this))
                .catch(function (error) {
                    console.log(error);
                });
            }
        }
}
   
</script>
<style scoped>
.input_img {
    left:1rem;
    top:1rem;
    
}
#input_img_btn {
    left:1.5rem;
    top:1.3rem;
    
}
#input_img_btn:focus{
    outline: none;
    color: red;
}
@media only screen and (max-width: 580px)
{
.mob-menu{
    top:0;
    left:0;
    height: auto;
}
}

</style>
