<template>
  <div class="bg-white rounded-lg h-full w-full py-3 px-6 md:relative">
        <Heading title="404"></Heading>
        <Menu class="fixed overflow-hidden mob-menu"></Menu>
        <div class="text-center">
            <h1 class="text-5xl text-red-700 font-mono">
                404  - Page Not Found
            </h1>
            <h3 class="mt-6 text-3xl"> 
                Goto Home Page <router-link class="text-blue-400" to="/">Home</router-link>
            </h3>
        </div>
  </div>
</template>
<script>
import Heading from '../components/Heading.vue'
import Menu from '../components/Menu.vue'

export default {
    components:{
        Heading,
        Menu
    }
   
}
</script>
<style scoped>
.timeline__item:before {
    content: "";
    background-color: #63B3ED;
    box-shadow: 0 0 0 3px #63B3ED;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -5px;
    height: 10px;
    width: 10px;
}


.timeline {
    margin-top: 1.8rem;
    padding-left: .5rem;
}
.timeline__item:last-child {
    border: 0;
}
.timeline__item {
    border-left: 2px solid #cbcbce;
    padding-bottom: .6rem;
    padding-left: 1.5rem;
    position: relative;
}
@media only screen and (max-width: 580px)
{
.mob-menu{
    top:0;
    left:0;
    height: auto;
}
}
</style>
