<template>
<div class="bg-white rounded-lg h-full w-full pt-3 md:relative">
    <Heading class="px-6" title="Me" msg="I'm Paras Suthar, a full stack developer specialist at Laravel, Vue.js, WordPress &  In Core PHP."></Heading>
    <Menu class="fixed overflow-hidden mob-menu"></Menu>
    <div class="bg-blue-400 rounded-lg rounded-t-none mt-3">
        
            <div class="text-2xl font-bold text-gray-800 pl-5 pt-5">What I'm Doing</div>
        
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 pt-5">
            <div class="p-3 pt-0 md:col-span-1 lg:col-span-2 lg:mx-2">
                
                    <div class="md:flex bg-white rounded-lg p-6 xl:h-auto lg:h-56 md:h-40 px-auto">
                        <img class="h-16 w-16 md:h-20 md:w-20 mx-auto md:mx-0 md:mr-6" src="../assets/img/img1.png">
                        <div class="text-center md:text-left">
                          <h2 class="text-lg font-bold">Web Development</h2>
                          <div class="text-gray-700">High-quality development of sites at the professional level.</div>
                          
                        </div>
                    </div>
            </div>
            <div class="p-3 pt-0 md:col-span-1 lg:col-span-2 lg:mx-2">
                
                    <div class="md:flex bg-white rounded-lg p-6 xl:h-auto lg:h-56 md:h-40 px-auto">
                        <img class="h-16 w-16 md:h-20 md:w-20 mx-auto md:mx-0 md:mr-6" src="../assets/img/img2.png">
                        <div class="text-center md:text-left">
                          <h2 class="text-lg font-bold">Cloud Server</h2>
                        <div class="text-gray-700">High-quality deployment of cloud server at the professional level.</div>
                          
                        </div>
                    </div>
            </div>
            <div class="p-3 pt-0 md:col-span-1 lg:col-span-2 lg:mx-2">
                
                    <div class="md:flex bg-white rounded-lg p-6 xl:h-auto lg:h-56 md:h-40 px-auto">
                        <img class="h-16 w-16 md:h-20 md:w-20 mx-auto md:mx-0 md:mr-6" src="../assets/img/img3.png">
                        <div class="text-center md:text-left">
                          <h2 class="text-lg font-bold">Mobile Applications</h2>
                          <div class="text-gray-700">Professional development of applications for Android.</div>
                          
                        </div>
                    </div>
            </div>
            <div class="p-3 pt-0 md:col-span-1 lg:col-span-2 lg:mx-2">
                
                    <div class="md:flex bg-white rounded-lg p-6 xl:h-auto lg:h-56 md:h-40 px-auto">
                        <img class="h-16 w-16 md:h-20 md:w-20 mx-auto md:mx-0 md:mr-6" src="../assets/img/img4.png">
                        <div class="text-center md:text-left">
                          <h2 class="text-lg font-bold">Traveling</h2>
                          <div class="text-gray-700">I love to travel. Traveling tends to magnify all human emotions.</div>
                          
                        </div>
                    </div>
            </div>
        </div>
        
    </div>
</div>
</template>
<script>
import Heading from '../components/Heading.vue'
import Menu from '../components/Menu.vue'

export default {
    components:{
        Heading,
        Menu
    },
       
}
</script>
<style scoped>
@media only screen and (max-width: 580px)
{
.mob-menu{
    top:0;
    left:0;
    height: auto;
}
}

</style>
