<template>
    <div class="circle-menu md:absolute rounded-b-lg md:rounded-full">
        <router-link class=" p-1 md:p-5 text-lg text-white" to="/">About</router-link> |
        <router-link class="p-1 md:p-5 text-lg text-white" to="/learning">Learning</router-link>|
        <!-- <router-link class="p-1 md:p-5 text-lg text-white" to="/resume">Resume</router-link>| -->
        <!-- <router-link class="p-1 md:p-5 text-lg text-white" to="/blog">Blog</router-link>| -->
        <router-link class="p-1 md:p-5 text-lg text-white" to="/Contact">Contact</router-link>
    </div>
</template>
<script>
export default {
    name:'Heading',
    props:{
        title:String
    }
}
</script>
<style scoped>
.circle-menu{
    background-color: #4a535c;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 3.5rem;
    top: 0;
    right: 0;
    width: auto;
    z-index: 2;
}
</style>