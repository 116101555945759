<template>
  <div class="bg-white rounded-lg h-full w-full py-3 px-6 md:relative">
        <Heading title="Learning" msg="There a lot of things to learn and I have learned a few of these."></Heading>
        <Menu class="fixed overflow-hidden mob-menu"></Menu>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 pt-5">
            <div class="p-3 pt-0 md:col-span-1 lg:col-span-2 lg:mx-2">
                <p class="text-3xl font-bold text-gray-700">Frameworks</p>
                <ul class="p-5">
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Laravel
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> CodeIgnator
                    </li>
                    
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox"  disabled/> ASP.Net
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Vuejs
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Nodejs
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> React Basic
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox"  disabled/> Angular
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> BootStrap
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox"  disabled/> Express
                    </li>
                    
                </ul>
            </div>
            <div class="p-3 pt-0 md:col-span-1 lg:col-span-2 lg:mx-2">
                <p class="text-3xl font-bold text-gray-700">Languages</p>
                <ul class="p-2 lg:p-5">
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> C
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> C++
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Vb.Net
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> PHP
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox"  disabled/> Java
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> JavaScript
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox"  disabled/> Go
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox"  disabled/> TypeScript
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox"  disabled/> Python
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox"  disabled/> Ruby
                    </li>
                    
                </ul>
            </div>

        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 pt-5">
            <div class="p-3 pt-0 md:col-span-1 lg:col-span-2 lg:mx-2">
                <p class="text-3xl font-bold text-gray-700">WordPress</p>
                <ul class="p-5">
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox"  disabled/> Theme Development
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox"  disabled/> Plugin Development
                    </li>
                    
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Customization
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Performance
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Bug Fixing
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Clean-up & Security
                    </li>
                   
                    
                </ul>
            </div>
            <div class="p-3 pt-0 md:col-span-1 lg:col-span-2 lg:mx-2">
                <p class="text-3xl font-bold text-gray-700">Tools/Concepts</p>
                <ul class="p-2 lg:p-5">
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Native Android Dev Basics
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Git
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Static Site Generators
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox"  disabled/> Gulp
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox"  disabled/> Grunt
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> MVC
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked  disabled/> CRUD
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> jQuery/Ajax
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> DOM
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Bitwise Operators

                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> JavaScript Closures

                    </li>
                  
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Async/Await
                    </li>
                </ul>
            </div>

        </div>
        
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 pt-5">
            <div class="p-3 pt-0 md:col-span-1 lg:col-span-2 lg:mx-2">
                <p class="text-3xl font-bold text-gray-700">Database</p>
                <ul class="p-5">
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> SQL (MySQL, PostgreSQL, MariaDB)
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Firebase (Basics)
                    </li>
                    
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox"  disabled/> MangoDB
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox"  disabled/> GraphQL
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Oracle
                    </li>
                </ul>
            </div>
            <div class="p-3 pt-0 md:col-span-1 lg:col-span-2 lg:mx-2">
                <p class="text-3xl font-bold text-gray-700">OOP Concepts</p>
                <ul class="p-2 lg:p-5">
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Class & Object
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Inheritance
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Polymorphism
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Abstraction
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Encapsulation
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Method Overloading & Overriding
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Coupling
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Composition
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Aggregation
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Association
                    </li>
                    
                    
                </ul>
            </div>

        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 pt-5">
            <div class="px-3 md:col-span-1 lg:col-span-2 lg:mx-2">
                <p class="text-3xl font-bold text-gray-700">Severs</p>
                <ul class="p-5">
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Hostinger
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Cpanel
                    </li>
                    
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox"  disabled/> AWS
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked  disabled/> Work with Git, Docker & Popular CLI
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Netlify
                    </li>
                </ul>
            </div>
            <div class="p-3 pt-0 md:col-span-1 lg:col-span-2 lg:mx-2">
                <p class="text-3xl font-bold text-gray-700">OS</p>
                <ul class="p-2 lg:p-5">
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Windows
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Linux Mint (Fav <i class=" text-red-700 fas fa-heart"></i>)
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" disabled/> Mac
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Kali Basics
                    </li>
                    <li class="p-2 text-gray-600 text-sm">
                        <input class="p-2 bg-gray-500" type="checkbox" checked disabled/> Ubantu
                    </li>
                    
                    
                </ul>
            </div>

        </div>

  </div>
</template>
<script>
import Heading from '../components/Heading.vue'
import Menu from '../components/Menu.vue'

export default {
    components:{
        Heading,
        Menu
    },
   
}
</script>
<style scoped>
@media only screen and (max-width: 580px)
{
.mob-menu{
    top:0;
    left:0;
    height: auto;
}
}
</style>
