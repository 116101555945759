<template>
    <div>
        <h1 class="text-4xl font-bold text-gray-700">{{title}}</h1>
        <h1 class="title-seperate mt-2"></h1>
        <br>
        <p>
            {{msg}}
        </p>
    </div>
</template>
<script>
export default {
    name:'Heading',
    props:{
        title:String,
        msg:String,
    }
}
</script>
<style scoped>
.title-seperate
{
    background-color: #63B3ED;
    border-radius: .5rem;
    height: 5px;
    position: absolute;
    width: 3.12rem;
}
</style>